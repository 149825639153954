import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'points_of_interest_education';
const namespace = 'ui-pdp-icon ui-pdp-icon--poi-education';

const IconEducation = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="18"
    viewBox="0 0 25 24"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconEducation.propTypes = {
  className: string,
};

IconEducation.defaultProps = {
  className: null,
};

IconEducation.ICON_ID = ICON_ID;

export default React.memo(IconEducation);
export { IconEducation };
